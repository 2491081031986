import React from 'react';
import { useGetUserItemsQuery } from '../../api/general';

const ItemsPage = () => {
  const { data: items, isLoading } = useGetUserItemsQuery();

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">My Items</h3>
        {isLoading && <p>Loading...</p>}
        {items && items.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Description</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {items.map(item => (
                <tr key={item.uuid}>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                  <td>{item.quantity} / {item.maxStack}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No items found</p>
        )}
      </div>
    </div>
  );
};

export default ItemsPage;
