import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import { useRegisterMutation } from '../../api/auth.js';
import useIP from '../../auth/useIp.js';
import Response from '../../shared-components/response.jsx';

function Register() {
  const context = useContext(AppSettings);
  const [email, setEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [responseMessage, setResponseMessage] = useState({ message: '', type: '' });
  const { ip } = useIP();
  const [register] = useRegisterMutation();

  useEffect(() => {
    context.handleSetAppSidebarNone(true);
    context.handleSetAppHeaderNone(true);
    context.handleSetAppContentClass('p-0');

    return () => {
      context.handleSetAppSidebarNone(false);
      context.handleSetAppHeaderNone(false);
      context.handleSetAppContentClass('');
    };
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await register({ email, emailConfirm, password, passwordConfirm, ip }).unwrap();
      setResponseMessage({ message: result.error[0].message, type: result.error[0].type });
      if (result.error[0].type === 'success') {
        setEmail('');
        setEmailConfirm('');
        setPassword('');
        setPasswordConfirm('');
      }
    } catch (err) {
      if (err.data && err.data.error && err.data.error.length > 0) {
        setResponseMessage({
          message: err.data.error[0].message,
          type: err.data.error[0].type
        });
      } else {
        setResponseMessage({ message: 'An unexpected error occurred', type: 'failure' });
      }
    }
  };

  return (
    <div className="register register-with-news-feed">
      <div className="news-feed">
        <div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-9.jpg)' }}></div>
        <div className="news-caption">
          <h4 className="caption-title"><b>UK</b> Mafia</h4>
          <p>
            Sign up here to get in on the action!
          </p>
        </div>
      </div>
      <div className="register-container">
        <div className="register-header mb-25px h1">
          <div className="mb-1">Sign Up</div>
          <small className="d-block fs-15px lh-16">Create your UKM Account. It's free and always will be.</small>
        </div>
        <div className="register-content">
          <form onSubmit={handleSubmit} className="fs-13px">
            <div className="mb-3">
              <label className="mb-2">Email <span className="text-danger">*</span></label>
              <input type="email" className="form-control fs-13px" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="mb-3">
              <label className="mb-2">Re-enter Email <span className="text-danger">*</span></label>
              <input type="email" className="form-control fs-13px" placeholder="Re-enter email address" value={emailConfirm} onChange={(e) => setEmailConfirm(e.target.value)} />
            </div>
            <div className="mb-3">
              <label className="mb-2">Password <span className="text-danger">*</span></label>
              <input type="password" className="form-control fs-13px" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className="mb-4">
              <label className="mb-2">Confirm Password <span className="text-danger">*</span></label>
              <input type="password" className="form-control fs-13px" placeholder="Confirm password" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
            </div>
            <div className="form-check mb-4">
              <input className="form-check-input" type="checkbox" value="" id="agreementCheckbox" />
              <label className="form-check-label" htmlFor="agreementCheckbox">
                By clicking Sign Up, you agree to our <Link to="/register">Terms</Link> and that you have read our <Link to="/register">Data Policy</Link>, including our <Link to="/auth/register">Cookie Use</Link>.
              </label>
            </div>
            <div className="mb-4">
              <button type="submit" className="btn btn-theme d-block w-100 btn-lg h-45px fs-13px">Sign Up</button>
            </div>
            <Response message={responseMessage.message} type={responseMessage.type} />
            <div className="mb-4 pb-5">
              Already a member? Click <Link to="/login">here</Link> to login.
            </div>
            <hr className="bg-gray-600 opacity-2" />
            <p className="text-center text-gray-600">
              &copy; UK Mafia All Right Reserved 2025
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Register;
