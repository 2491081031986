import { apiSlice } from '../auth/apiSlice';

export const ocApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // OC Membership
        checkMembership: builder.query({
            query: () => '/oc/check-membership',
            providesTags: ['OCMembership'],
            refetchOnMountOrArgChange: true
        }),
        startOC: builder.mutation({
            query: body => ({
                url: '/oc/start',
                method: 'POST',
                body: { ...body },
            }),
            invalidatesTags: ['OCMembership']
        }),

        // OC Invites
        getInvites: builder.query({
            query: () => '/oc/invites',
            providesTags: ['OCInvites'],
            refetchOnMountOrArgChange: true
        }),
        respondToOCInvite: builder.mutation({
            query: (data) => ({
                url: '/oc/respond-to-oc-invite',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['OCInvites', 'OCMembership']
        }),
        
        
        getPublicOCs: builder.query({
            query: () => '/oc/public',
            providesTags: ['PublicOCs'],
            refetchOnMountOrArgChange: true
        }),
        getOCDetails: builder.query({
            query: () => '/oc/details',
            providesTags: ['OCDetails'],
            refetchOnMountOrArgChange: true
        }),
        inviteToOC: builder.mutation({
            query: body => ({
                url: '/oc/invite',
                method: 'POST',
                body: { ...body },
            }),
            invalidatesTags: ['OCDetails']
        }),
        doOC: builder.mutation({
            query: () => ({
                url: '/oc/execute',
                method: 'POST'
            }),
            invalidatesTags: ['OCDetails']
        }),
        disbandOC: builder.mutation({
            query: () => ({
                url: '/oc/disband',
                method: 'POST'
            }),
            invalidatesTags: ['OCMembership']
        }),
        getLocalVehicles: builder.query({
            query: () => '/oc/local-vehicles',
            providesTags: ['LocalVehicles']
        }),
        submitWeapons: builder.mutation({
            query: (data) => ({
                url: '/oc/weapons/submit',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['OCMembership']
        }),
        
        submitDemolitions: builder.mutation({
            query: (data) => ({
                url: '/oc/demolitions/submit',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['OCMembership']
        }),
        
        submitVehicle: builder.mutation({
            query: (data) => ({
                url: '/oc/driver/submit',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['OCMembership']
        }),
        
        
        
        
    }),
});

export const {
    useCheckMembershipQuery,
    useStartOCMutation,
    useGetInvitesQuery,
    useRespondToOCInviteMutation,
    useGetPublicOCsQuery,
    useGetOCDetailsQuery,
    useInviteToOCMutation,
    useDoOCMutation,
    useDisbandOCMutation,
    useGetLocalVehiclesQuery,
    useSubmitWeaponsMutation,
    useSubmitDemolitionsMutation,
    useSubmitVehicleMutation,
    

} = ocApi;
