import React, { useState } from 'react';
import { useGetInsuranceQuery, useBuyInsuranceMutation } from '../../api/marketplace';
import Response from '../../shared-components/response';

const LifeInsurance = () => {
  const { data: insuranceData } = useGetInsuranceQuery();
  const [buyInsurance] = useBuyInsuranceMutation();
  const [selectedInsurance, setSelectedInsurance] = useState('');
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handleInsurancePurchase = async () => {
    try {
      const result = await buyInsurance({ insuranceType: selectedInsurance }).unwrap();
      setAlertData({ message: result.message, type: result.type });
    } catch (error) {
      setAlertData({ message: 'Error purchasing insurance', type: 'danger' });
    }
  };

  const getCurrentInsurance = () => {
    const policies = ['None', 'Low', 'Medium', 'High', 'Mega'];
    return policies[insuranceData?.insurance?.policyType || 0];
  };

  const canSelectInsurance = (value) => {
    const currentPolicy = insuranceData?.insurance?.policyType || 0;
    return parseInt(value) > currentPolicy;
  };

  return (
    <div>
      <Response message={alertData.message} type={alertData.type} />
  
      <div className="row">
        <div className="col-12">
          <div className="card mb-4">
            <div className="card-body">
              <h3 className="card-title text-center">Insurance Options</h3>
              <p className="text-center">Current Insurance: {getCurrentInsurance()}</p>
              <div className="d-flex flex-column flex-lg-row justify-content-between">
                {[
                  { type: 'Low', percentage: '15%', cost: '£1,000,000', value: '1' },
                  { type: 'Medium', percentage: '25%', cost: '£2,000,000', value: '2' },
                  { type: 'High', percentage: '35%', cost: '£4,000,000', value: '3' },
                  { type: 'Mega', percentage: '50%', cost: '60 Points', value: '4' }
                ].map((option) => (
                  <div 
                    key={option.value}
                    className={`insurance-option mb-3 p-3 border rounded ${selectedInsurance === option.value ? 'bg-primary text-white' : ''} ${!canSelectInsurance(option.value) ? 'opacity-50' : ''}`}
                    style={{ cursor: canSelectInsurance(option.value) ? 'pointer' : 'not-allowed', flex: '1', margin: '0 5px' }}
                    onClick={() => canSelectInsurance(option.value) && setSelectedInsurance(option.value)}
                  >
                    <h4>{option.type}</h4>
                    <p>Percentage: {option.percentage}</p>
                    <p>Cost: {option.cost}</p>
                  </div>
                ))}
              </div>
              <div className="text-center">
                <button 
                  onClick={handleInsurancePurchase} 
                  className="btn btn-primary"
                  disabled={!selectedInsurance}
                >
                  Purchase Insurance
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LifeInsurance;
