import React, { useState } from 'react';

const MOCK_PASS = {
  type: 'GOLD', // null, BRONZE, SILVER, GOLD
  visits: 150,
  stock: 25000,
  lockpicks: 1200,
  totalProduction: 125000
};

const MOCK_PROGRESS = [
  { id: 1, type: 'Cars', melting: 5, bullets: 2500, lockpicks: 625, cost: '£250,000', timeLeft: '2h 30m' },
  { id: 2, type: 'Boats', melting: 3, bullets: 1500, lockpicks: 375, cost: '£150,000', timeLeft: '1h 15m' }
];

const PASS_TYPES = {
  BRONZE: {
    bulletsPerUnit: 1,
    maxStock: 20000,
    arrivalTime: '3 Hours',
    price: 150000,
    extra: 0
  },
  SILVER: {
    bulletsPerUnit: 2,
    maxStock: 60000,
    arrivalTime: '2 Hours',
    price: 250000,
    extra: 2
  },
  GOLD: {
    bulletsPerUnit: 3,
    maxStock: 200000,
    arrivalTime: '1 Hour',
    price: 400000,
    extra: 5
  }
};

function WelcomeSection() {
  return (
    <div className="row">
      <div className="col-lg-2"></div>
      <div className="col-lg-8">
        <div className="card">
          <div className="card-header">Welcome to the Refinery</div>
          <div className="card-body">
            <img className="w-100" src="/images/refinery.jpg" alt="Refinery" />
            <p className="text-center mt-3">
              Welcome to the refinery! Here you can melt down your stolen cars for bullets. 
              Select a trading pass to access the refinery features.
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th>Features</th>
                  <th>BRONZE</th>
                  <th>SILVER</th>
                  <th>GOLD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Price</td>
                  <td>£150,000</td>
                  <td>£250,000</td>
                  <td>£400,000</td>
                </tr>
                <tr>
                  <td>Buy Pass</td>
                  <td><button className="btn btn-primary">Buy Bronze</button></td>
                  <td><button className="btn btn-primary">Buy Silver</button></td>
                  <td><button className="btn btn-primary">Buy Gold</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function RefineryDashboard() {
  const [includeTankers, setIncludeTankers] = useState(false);

  return (
    <div className="row">
      <div className="col-lg-1"></div>
      <div className="col-lg-10">
        <div className="card">
          <div className="card-header">Metal Refinery</div>
          <div className="card-body">
            <img className="w-100" src="/images/refinery.jpg" alt="Refinery" />
            <div className="form-check form-switch text-end">
              <input 
                className="form-check-input" 
                type="checkbox"
                checked={includeTankers}
                onChange={(e) => setIncludeTankers(e.target.checked)}
              />
              <label className="form-check-label">Include Oil Tankers</label>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Stolen Vehicles</th>
                  <th>Melting Value (Bullets)</th>
                  <th>Price to Melt</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>5 Cars</td>
                  <td>2,500</td>
                  <td>£250,000</td>
                  <td><button className="btn btn-danger">Melt Cars</button></td>
                </tr>
                <tr>
                  <td>3 Boats</td>
                  <td>1,500</td>
                  <td>£150,000</td>
                  <td><button className="btn btn-danger">Melt Boats</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <div className="card">
            <div className="card-header text-center">
                You currently have a <span style={{ 
                  color: MOCK_PASS.type === 'GOLD' ? 'gold' : 
                        MOCK_PASS.type === 'SILVER' ? 'silver' : 
                        'bronze' 
                }}>{MOCK_PASS.type}</span> pass
              </div>
              <div className="card-body">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Jobs Done:</td>
                      <td>{MOCK_PASS.visits.toLocaleString()}</td>
                    </tr>
                    <tr>
                      <td>Current Stock:</td>
                      <td>{MOCK_PASS.stock.toLocaleString()}</td>
                    </tr>
                    <tr>
                      <td>Lockpicks:</td>
                      <td>{MOCK_PASS.lockpicks.toLocaleString()}</td>
                    </tr>
                    <tr>
                      <td>Production Total:</td>
                      <td>{MOCK_PASS.totalProduction.toLocaleString()}</td>
                    </tr>
                  </tbody>
                </table>
                {MOCK_PASS.stock >= 4000 && (
                  <button className="btn btn-primary w-100 mb-2">Collect All Stock</button>
                )}
                {MOCK_PASS.type !== 'GOLD' && (
                  <button className="btn btn-success w-100">Upgrade Pass</button>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
      <div className="col-lg-1"></div>
    </div>
  );
}

function Refinery() {
  return (
    <div>
      {MOCK_PASS.type ? <RefineryDashboard /> : <WelcomeSection />}
    </div>
  );
}

export default Refinery;
