import React, { useEffect } from 'react';
import { useGetStatsfeedQuery } from '../../api/auth';
import { useInterval } from 'react-use';
import { useGlobalState } from '../../auth/globalState';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../shared-components/numberFormat';

const profileStyles = {
  menuProfileInfo: {
  },
  profileStats: {
    fontSize: '0.9em',
    lineHeight: 1.2,
    display: 'flex',
    flexDirection: 'column',
  },
  statItem: {
    margin: '0',
  },
};

function SidebarProfile() {
  const { globalState } = useGlobalState();
  const { data: stats, refetch } = useGetStatsfeedQuery(undefined, {
    skip: !globalState.character_uuid,
  });

  useEffect(() => {
    if (globalState.character_uuid) {
      refetch();
    }
  }, [globalState.character_uuid, refetch]);

  useInterval(() => {
    if (globalState.character_uuid) {
      refetch();
    }
  }, 10000);

  return (
    <Link to={`/game/general/userprofile/${stats?.username}`} style={{ textDecoration: 'none', color: 'inherit' }}>

    <div className="menu">
      <div className="menu-profile">
        <div className="menu-profile-cover with-shadow"></div>
        <div className="menu-profile-info" style={profileStyles.menuProfileInfo}>
          {stats && (
            <div style={profileStyles.profileStats}>
              <p style={profileStyles.statItem}><i className="fas fa-medal me-1"></i>{stats.rank}</p>
              <p style={profileStyles.statItem}><i className="fas fa-wallet me-1"></i>£{formatNumber(stats.money)}</p>
                <p style={profileStyles.statItem}><i className="fas fa-gun me-1"></i>{formatNumber(stats.bullets)}</p>
                <p style={profileStyles.statItem}><i className="fas fa-shield-alt me-1"></i>{formatNumber(stats.kevlars)}</p>
                <p style={profileStyles.statItem}><i className="fas fa-key me-1"></i>{formatNumber(stats.lockpicks)}</p>
              <p style={profileStyles.statItem}><i className="fas fa-car me-1"></i>{stats.location}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  </Link>
  );
  
}

export default SidebarProfile;