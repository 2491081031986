import React, { useState } from 'react';
import { useSearchUsersQuery, useGetPlayersOnlineQuery } from '../../api/general';
import { Link } from 'react-router-dom';


const PlayersOnline = () => {
  const { data: onlinePlayers, isLoading } = useGetPlayersOnlineQuery();

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Players Online (Last 15 Minutes)</h3>
        {isLoading && <p>Loading...</p>}
        {onlinePlayers && onlinePlayers.length > 0 ? (
  <p>
    {onlinePlayers.map((player, index) => (
      <React.Fragment key={player.username}>
        <Link to={`/game/general/userprofile/${player.username}`}>
          {player.username}
        </Link>
        {index < onlinePlayers.length - 1 && ' - '}
      </React.Fragment>
    ))}
  </p>
) : (
  <p>No players online</p>
)}

      </div>
    </div>
  );
};

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { data: searchResults, isLoading } = useSearchUsersQuery(searchTerm, {
    skip: searchTerm.length < 3
  });

  const getStatusColor = (status) => {
    switch (status) {
      case 'Alive':
        return 'green';
      case 'Dead':
        return 'red';
      case 'Banned':
        return 'orange';
      default:
        return 'black';
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Search</h3>
        <input 
          type="text" 
          className="form-control mb-2" 
          placeholder="Search for players..." 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {isLoading && <p>Loading...</p>}
        {searchResults && searchResults.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th>Username</th>
                <th>Rank</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
            {searchResults.map(user => (
  <tr key={user.username}>
    <td>
      <Link to={`/game/general/userprofile/${user.username}`}>
        {user.username}
      </Link>
    </td>
    <td>{user.game_rank.rankNumber}</td>
    <td style={{ color: getStatusColor(user.status) }}>{user.status}</td>
  </tr>
))}

            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

const Directory = () => {
  const [activeTab, setActiveTab] = useState('playersOnline');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a href="#players-online-tab" onClick={() => handleTabClick('playersOnline')} className={`nav-link ${activeTab === 'playersOnline' ? 'active' : ''}`}>
            <span className="d-sm-none">Players Online</span>
            <span className="d-sm-block d-none">Players Online</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#search-tab" onClick={() => handleTabClick('search')} className={`nav-link ${activeTab === 'search' ? 'active' : ''}`}>
            <span className="d-sm-none">Search</span>
            <span className="d-sm-block d-none">Search</span>
          </a>
        </li>
      </ul>
      <div className="tab-content panel rounded-0 p-3 m-0">
        <div className={`tab-pane fade ${activeTab === 'playersOnline' ? 'active show' : ''}`} id="players-online-tab">
          <PlayersOnline />
        </div>
        <div className={`tab-pane fade ${activeTab === 'search' ? 'active show' : ''}`} id="search-tab">
          <Search />
        </div>
      </div>
    </div>
  );
};

export default Directory;
