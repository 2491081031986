import React, { useState } from 'react';
import { useGetGarageVehiclesQuery, useShipVehicleMutation, useSendVehicleMutation, useRepairVehicleMutation, useScrapVehicleMutation, useGetMarineVehiclesQuery,  useShipMarineVehicleMutation,
  useSendMarineVehicleMutation,
  useRepairMarineVehicleMutation,
  useScrapMarineVehicleMutation  } from '../../api/marketplace';
import { useGetLocationsQuery } from '../../api/auth';


import Response from '../../shared-components/response';

const Garage = () => {
  const { data: vehicles, isLoading, isError, refetch } = useGetGarageVehiclesQuery();
  const [shipVehicle] = useShipVehicleMutation();
  const [sendVehicle] = useSendVehicleMutation();
  const [repairVehicle] = useRepairVehicleMutation();
  const [scrapVehicle] = useScrapVehicleMutation();
  const { data: locations } = useGetLocationsQuery();

  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [destination, setDestination] = useState('');
  const [recipient, setRecipient] = useState('');
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [actionSuccess, setActionSuccess] = useState(false);

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      setSelectedVehicles(vehicles.map(v => v.uuid));
    } else {
      setSelectedVehicles([]);
    }
  };

  const handleCheck = (uuid) => {
    setSelectedVehicles(prev => 
      prev.includes(uuid) ? prev.filter(id => id !== uuid) : [...prev, uuid]
    );
  };

  const handleVehicleClick = (vehicle) => {
    setSelectedVehicle(vehicle);
    setShowModal(true);
    setDestination('');
    setRecipient('');
    setActionSuccess(false);
    setAlertData({ message: '', type: '' }); // Reset the alert data

  };

  const handleShip = async () => {
    try {
      const result = await shipVehicle({ uuid: selectedVehicle.uuid, destination }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      setActionSuccess(result.type === 'success');
      if (result.type === 'success') {
        refetch();
      }
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Failed to ship vehicle', type: error.data?.type || 'fail' });
    }
  };

  const handleSend = async () => {
    try {
      const result = await sendVehicle({ uuid: selectedVehicle.uuid, recipient }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      setActionSuccess(result.type === 'success');
      if (result.type === 'success') {
        refetch();
      }
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Failed to send vehicle', type: error.data?.type || 'fail' });
    }
  };

  const handleRepair = async () => {
    try {
      const result = await repairVehicle({ uuid: selectedVehicle.uuid }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      setActionSuccess(result.type === 'success');
      if (result.type === 'success') {
        refetch();
      }
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Failed to repair vehicle', type: error.data?.type || 'fail' });
    }
  };

  const handleScrap = async () => {
    try {
      const result = await scrapVehicle({ uuid: selectedVehicle.uuid }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      setActionSuccess(result.type === 'success');
      if (result.type === 'success') {
        refetch();
      }
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Failed to scrap vehicle', type: error.data?.type || 'fail' });
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading vehicles</div>;

  const getStatusColor = (status) => {
    return status === 'Stored In Garage' ? 'text-success' : 'text-warning';
  };

  return (
    <div>
      <h3>Garage</h3>
      <table className="table">
        <thead>
          <tr>
            <th>
              <input type="checkbox" onChange={handleCheckAll} />
              <span className="ms-3">Vehicle</span>
            </th>
            <th>Location</th>
            <th>Damage</th>
            <th>Value</th>
            <th>Status</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {vehicles.map(vehicle => (
            <tr key={vehicle.uuid}>
              <td>
                <input 
                  type="checkbox" 
                  checked={selectedVehicles.includes(vehicle.uuid)}
                  onChange={() => handleCheck(vehicle.uuid)}
                />
                <span className="ms-3">{vehicle.name}</span>
              </td>
              <td>{vehicle.location}</td>
              <td>{vehicle.damage}%</td>
              <td>${vehicle.value}</td>
              <td className={getStatusColor(vehicle.status)}>{vehicle.status}</td>
              <td>
                <button onClick={() => handleVehicleClick(vehicle)}>Actions</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showModal && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Actions for {selectedVehicle.name}</h5>
                <button type="button" className="close" onClick={() => setShowModal(false)}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <Response message={alertData.message} type={alertData.type} />

                {!actionSuccess ? (
                  <>
                    <h6>Ship Vehicle</h6>
                    <select className="form-control mb-2" value={destination} onChange={(e) => setDestination(e.target.value)}>
                      <option value="">Select destination</option>
                      {locations && locations.map(location => (
                        <option key={location.uuid} value={location.uuid}>{location.city}</option>
                      ))}
                    </select>
                    <button className="btn btn-primary mb-3" onClick={handleShip}>Ship</button>

                    <h6>Send Vehicle</h6>
                    <input 
                      type="text" 
                      className="form-control mb-2" 
                      placeholder="Enter username" 
                      value={recipient}
                      onChange={(e) => setRecipient(e.target.value)}
                    />
                    <button className="btn btn-primary mb-3" onClick={handleSend}>Send</button>

                    <h6>Repair Vehicle</h6>
                    <p>Repair Cost: ${selectedVehicle.repairCost}</p>
                    <button className="btn btn-primary mb-3" onClick={handleRepair}>Repair</button>

                    <h6>Scrap Vehicle</h6>
                    <button className="btn btn-danger" onClick={handleScrap}>Scrap Vehicle</button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


const Marina = () => {
  const { data: vehicles, isLoading, isError, refetch } = useGetMarineVehiclesQuery();
  const [shipMarineVehicle] = useShipMarineVehicleMutation();
  const [sendMarineVehicle] = useSendMarineVehicleMutation();
  const [repairMarineVehicle] = useRepairMarineVehicleMutation();
  const [scrapMarineVehicle] = useScrapMarineVehicleMutation();
  const { data: locations } = useGetLocationsQuery();

  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [destination, setDestination] = useState('');
  const [recipient, setRecipient] = useState('');
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [actionSuccess, setActionSuccess] = useState(false);

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      setSelectedVehicles(vehicles.map(v => v.uuid));
    } else {
      setSelectedVehicles([]);
    }
  };

  const handleCheck = (uuid) => {
    setSelectedVehicles(prev => 
      prev.includes(uuid) ? prev.filter(id => id !== uuid) : [...prev, uuid]
    );
  };

  const handleVehicleClick = (vehicle) => {
    setSelectedVehicle(vehicle);
    setShowModal(true);
    setDestination('');
    setRecipient('');
    setActionSuccess(false);
    setAlertData({ message: '', type: '' });
  };

  const handleShip = async () => {
    try {
      const result = await shipMarineVehicle({ uuid: selectedVehicle.uuid, destination }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      setActionSuccess(result.type === 'success');
      if (result.type === 'success') {
        refetch();
      }
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Failed to ship marine vehicle', type: error.data?.type || 'fail' });
    }
  };

  const handleSend = async () => {
    try {
      const result = await sendMarineVehicle({ uuid: selectedVehicle.uuid, recipient }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      setActionSuccess(result.type === 'success');
      if (result.type === 'success') {
        refetch();
      }
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Failed to send marine vehicle', type: error.data?.type || 'fail' });
    }
  };

  const handleRepair = async () => {
    try {
      const result = await repairMarineVehicle({ uuid: selectedVehicle.uuid }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      setActionSuccess(result.type === 'success');
      if (result.type === 'success') {
        refetch();
      }
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Failed to repair marine vehicle', type: error.data?.type || 'fail' });
    }
  };

  const handleScrap = async () => {
    try {
      const result = await scrapMarineVehicle({ uuid: selectedVehicle.uuid }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      setActionSuccess(result.type === 'success');
      if (result.type === 'success') {
        refetch();
      }
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Failed to scrap marine vehicle', type: error.data?.type || 'fail' });
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading marine vehicles</div>;

  const getStatusColor = (status) => {
    return status === 'Docked' ? 'text-success' : 'text-warning';
  };

  return (
    <div>
      <Response message={alertData.message} type={alertData.type} />
      <h3>Marina</h3>
      <table className="table">
        <thead>
          <tr>
            <th>
              <input type="checkbox" onChange={handleCheckAll} />
              <span className="ms-3">Vehicle</span>
            </th>
            <th>Location</th>
            <th>Damage</th>
            <th>Value</th>
            <th>Status</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {vehicles.map(vehicle => (
            <tr key={vehicle.uuid}>
              <td>
                <input 
                  type="checkbox" 
                  checked={selectedVehicles.includes(vehicle.uuid)}
                  onChange={() => handleCheck(vehicle.uuid)}
                />
                <span className="ms-3">{vehicle.name}</span>
              </td>
              <td>{vehicle.location}</td>
              <td>{vehicle.damage}%</td>
              <td>${vehicle.value}</td>
              <td className={getStatusColor(vehicle.status)}>{vehicle.status}</td>
              <td>
                <button onClick={() => handleVehicleClick(vehicle)}>Actions</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showModal && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Actions for {selectedVehicle.name}</h5>
                <button type="button" className="close" onClick={() => setShowModal(false)}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {!actionSuccess ? (
                  <>
                    <h6>Ship Marine Vehicle</h6>
                    <select className="form-control mb-2" value={destination} onChange={(e) => setDestination(e.target.value)}>
                      <option value="">Select destination</option>
                      {locations && locations.map(location => (
                        <option key={location.uuid} value={location.uuid}>{location.city}</option>
                      ))}
                    </select>
                    <button className="btn btn-primary mb-3" onClick={handleShip}>Ship</button>

                    <h6>Send Marine Vehicle</h6>
                    <input 
                      type="text" 
                      className="form-control mb-2" 
                      placeholder="Enter username" 
                      value={recipient}
                      onChange={(e) => setRecipient(e.target.value)}
                    />
                    <button className="btn btn-primary mb-3" onClick={handleSend}>Send</button>

                    <h6>Repair Marine Vehicle</h6>
                    <p>Repair Cost: ${selectedVehicle.repairCost}</p>
                    <button className="btn btn-primary mb-3" onClick={handleRepair}>Repair</button>

                    <h6>Scrap Marine Vehicle</h6>
                    <button className="btn btn-danger" onClick={handleScrap}>Scrap Marine Vehicle</button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


const Dealership = () => {
  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Dealership</h3>
        {/* Add dealership content here */}
      </div>
    </div>
  );
};

const Vehicles = () => {
  const [activeTab, setActiveTab] = useState('garage');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a href="#garage-tab" onClick={() => handleTabClick('garage')} className={`nav-link ${activeTab === 'garage' ? 'active' : ''}`}>
            <span className="d-sm-none">Garage</span>
            <span className="d-sm-block d-none">Garage</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#marina-tab" onClick={() => handleTabClick('marina')} className={`nav-link ${activeTab === 'marina' ? 'active' : ''}`}>
            <span className="d-sm-none">Marina</span>
            <span className="d-sm-block d-none">Marina</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#dealership-tab" onClick={() => handleTabClick('dealership')} className={`nav-link ${activeTab === 'dealership' ? 'active' : ''}`}>
            <span className="d-sm-none">Dealership</span>
            <span className="d-sm-block d-none">Dealership</span>
          </a>
        </li>
      </ul>
      <div className="tab-content panel rounded-0 p-3 m-0">
        <div className={`tab-pane fade ${activeTab === 'garage' ? 'active show' : ''}`} id="garage-tab">
          <Garage />
        </div>
        <div className={`tab-pane fade ${activeTab === 'marina' ? 'active show' : ''}`} id="marina-tab">
          <Marina />
        </div>
        <div className={`tab-pane fade ${activeTab === 'dealership' ? 'active show' : ''}`} id="dealership-tab">
          <Dealership />
        </div>
      </div>
    </div>
  );
};

export default Vehicles;
