import React, { useState } from 'react';
import { useGetPlayerProfileQuery } from '../../api/general';
import Response from '../../shared-components/response';
import { useParams } from 'react-router-dom';


const UserProfile = () => {
	const { username } = useParams();

  const [activeTab, setActiveTab] = useState('bio');
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const { data: userProfile, isLoading } = useGetPlayerProfileQuery(username);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setAlertData({ message: '', type: '' });
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a href="#bio" onClick={() => handleTabClick('bio')} className={`nav-link ${activeTab === 'bio' ? 'active' : ''}`}>Bio</a>
        </li>
        <li className="nav-item">
          <a href="#publicKills" onClick={() => handleTabClick('publicKills')} className={`nav-link ${activeTab === 'publicKills' ? 'active' : ''}`}>Public Kills</a>
        </li>
      </ul>
      <div className="tab-content panel rounded-0 p-3 m-0">
        <Response message={alertData.message} type={alertData.type} />

        <div className={`tab-pane fade ${activeTab === 'bio' ? 'active show' : ''}`} id="bio-tab">
          <BioTab userProfile={userProfile} />
        </div>
        <div className={`tab-pane fade ${activeTab === 'publicKills' ? 'active show' : ''}`} id="publicKills-tab">
          <PublicKillsTab userProfile={userProfile} />
        </div>
      </div>
    </div>
  );
};


const BioTab = ({ userProfile }) => (
	<div className="bio-tab row">
	  <div className="col-md-4">
		<img src={userProfile?.profile_image || "/assets/img/default_profileimage.png"} alt={userProfile.name} className="img-fluid rounded" />
	  </div>
	  <div className="col-md-8">
		<h2>{userProfile.name}</h2>
		<table className="table">
		  <tbody>
			<tr>
			  <td>Rank:</td>
			  <td>{userProfile.rankName} ({userProfile.rankNumber})</td>
			</tr>
			<tr>
			  <td>Family:</td>
			  <td>{userProfile.familyName}</td>
			</tr>
			<tr>
			  <td>Created:</td>
			  <td>{new Date(userProfile.createdAt).toLocaleDateString()}</td>
			</tr>
			<tr>
			  <td>Bio:</td>
			  <td>{userProfile?.bio || "No bio available"}</td>
			</tr>
			<tr>
			  <td>Quote:</td>
			  <td>{userProfile?.profileQuote || "No quote available"}</td>
			</tr>
		  </tbody>
		</table>
		{userProfile?.profileMusic && (
		  <div className="profile-music mt-3">
			<h4>{userProfile.name}'s Music Choice</h4>
			<iframe 
			  src={`https://www.youtube.com/embed/${userProfile.profileMusic}?autoplay=1;rel=0&amp;controls=0&amp;showinfo=0&amp;modestbranding=1&amp;mute=0&amp;`} 
			  title="YouTube video player" 
			  frameBorder="0"
			  className="w-100"
			></iframe>
		  </div>
		)}
	  </div>
	</div>
  );

  
  

const PublicKillsTab = ({ userProfile }) => (
  <div className="public-kills-tab">
    <h3>Public Kills</h3>
    <p>This feature is not yet implemented.</p>
  </div>
);

export default UserProfile;
