import React, { useState } from 'react';
import { useGetIdeasQuery, useSubmitIdeaMutation, useVoteIdeaMutation, useCommentIdeaMutation, useGetIdeaCommentsQuery } from '../../api/comm';
import Response from '../../shared-components/response';

const IdeaModal = ({ idea, onClose, onVote }) => {
    const [comment, setComment] = useState('');
    const [postComment] = useCommentIdeaMutation();
    const [voteIdea] = useVoteIdeaMutation();
    const { data: comments, isLoading, refetch } = useGetIdeaCommentsQuery(idea.uuid);
    const [userVoted, setUserVoted] = useState(false);
    const [alertData, setAlertData] = useState({ message: '', type: '' });
  
    const handleCommentSubmit = async () => {
      try {
        await postComment({ ideaId: idea.uuid, content: comment });
        setComment('');
        refetch();
      } catch (error) {
        console.error('Error posting comment:', error);
      }
    };
  
    const handleVote = async (vote) => {
        if (!userVoted) {
          try {
            const response = await voteIdea({ ideaId: idea.uuid, vote }).unwrap();
            setUserVoted(true);
            onVote(idea.uuid, vote);
            setAlertData({ message: response.message, type: response.type });
          } catch (error) {
            console.error('Error voting:', error);
            setAlertData({ message: error.data?.error || 'Failed to record vote. Please try again.', type: 'error' });
          }
        }
      };
      
  
    return (
      <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{idea.title}</h4>
              <button type="button" className="close" onClick={onClose}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{idea.content}</p>
              <Response message={alertData.message} type={alertData.type} />
              <div className="mt-3">
                <button 
                  onClick={() => handleVote('yes')} 
                  className="btn btn-success mr-2"
                  disabled={userVoted}
                >
                  Vote Yes
                </button>
                <button 
                  onClick={() => handleVote('no')} 
                  className="btn btn-danger"
                  disabled={userVoted}
                >
                  Vote No
                </button>
              </div>
              <h5 className="mt-4">Comments</h5>
              {isLoading ? (
                <p>Loading comments...</p>
              ) : (
                comments && comments.map(comment => (
                  <div key={comment.uuid} className="card mt-2">
                    <div className="card-body">
                      <p>{comment.comment}</p>
                      <small>By {comment.commenter.username}</small>
                    </div>
                  </div>
                ))
              )}
              <div className="mt-4">
                <textarea 
                  className="form-control" 
                  value={comment} 
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Write a comment..."
                />
                <button 
                  className="btn btn-primary mt-2" 
                  onClick={handleCommentSubmit}
                >
                  Post Comment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  

const IdeaList = ({ status, onIdeaClick }) => {
  const { data: ideas, isLoading } = useGetIdeasQuery(status);

  if (isLoading) return <p>Loading...</p>;

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Title</th>
          <th>Author</th>
          <th>Votes</th>
        </tr>
      </thead>
      <tbody>
        {ideas && ideas.map(idea => (
          <tr key={idea.id} onClick={() => onIdeaClick(idea)} style={{ cursor: 'pointer' }}>
            <td>{idea.title}</td>
            <td>{idea.author.username}</td>
            <td>
  <span style={{ color: 'green' }}>{idea.yes_votes}</span> / <span style={{ color: 'red' }}>{idea.no_votes}</span>
</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const SubmitIdea = ({ setAlertData }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [submitIdea] = useSubmitIdeaMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await submitIdea({ title, content }).unwrap();
      setAlertData({ message: 'Idea submitted successfully!', type: 'success' });
      setTitle('');
      setContent('');
    } catch (error) {
      setAlertData({ message: 'Failed to submit idea. Please try again.', type: 'error' });
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Submit an Idea</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="content">Idea:</label>
            <textarea
              className="form-control"
              id="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">Post Idea</button>
        </form>
      </div>
    </div>
  );
};

const IdeaCentre = () => {
  const [activeTab, setActiveTab] = useState('pending');
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [voteIdea] = useVoteIdeaMutation();
  const [commentIdea] = useCommentIdeaMutation();

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setAlertData({ message: '', type: '' });
  };

  const handleIdeaClick = (idea) => {
    setSelectedIdea(idea);
  };

  const handleVote = async (ideaId, vote) => {
    await voteIdea({ ideaId, vote });
  };

  const handleComment = async (ideaId, content) => {
    await commentIdea({ ideaId, content });
  };

  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a href="#pending-tab" onClick={() => handleTabClick('pending')} className={`nav-link ${activeTab === 'pending' ? 'active' : ''}`}>
            <span className="d-sm-none">Pending</span>
            <span className="d-sm-block d-none">Pending</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#made-tab" onClick={() => handleTabClick('made')} className={`nav-link ${activeTab === 'made' ? 'active' : ''}`}>
            <span className="d-sm-none">Made</span>
            <span className="d-sm-block d-none">Made</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#progress-tab" onClick={() => handleTabClick('progress')} className={`nav-link ${activeTab === 'progress' ? 'active' : ''}`}>
            <span className="d-sm-none">In Progress</span>
            <span className="d-sm-block d-none">In Progress</span>
          </a>
        </li>
        <li className="nav-item">
          <a href="#declined-tab" onClick={() => handleTabClick('declined')} className={`nav-link ${activeTab === 'declined' ? 'active' : ''}`}>
            <span className="d-sm-none">Declined</span>
            <span className="d-sm-block d-none">Declined</span>
          </a>
        </li>
      </ul>
      <div className="tab-content panel rounded-0 p-3 m-0">
        <div className={`tab-pane fade ${activeTab === 'pending' ? 'active show' : ''}`} id="pending-tab">
          <IdeaList status="0" onIdeaClick={handleIdeaClick} />
        </div>
        <div className={`tab-pane fade ${activeTab === 'made' ? 'active show' : ''}`} id="made-tab">
          <IdeaList status="1" onIdeaClick={handleIdeaClick} />
        </div>
        <div className={`tab-pane fade ${activeTab === 'progress' ? 'active show' : ''}`} id="progress-tab">
          <IdeaList status="2" onIdeaClick={handleIdeaClick} />
        </div>
        <div className={`tab-pane fade ${activeTab === 'declined' ? 'active show' : ''}`} id="declined-tab">
          <IdeaList status="3" onIdeaClick={handleIdeaClick} />
        </div>
      </div>
      <div className="mt-4">
        <Response message={alertData.message} type={alertData.type} />
        <SubmitIdea setAlertData={setAlertData} />
      </div>
      {selectedIdea && (
        <IdeaModal 
          idea={selectedIdea} 
          onClose={() => setSelectedIdea(null)}
          onVote={handleVote}
          onComment={handleComment}
        />
      )}
    </div>
  );
};

export default IdeaCentre;
