import React, { useState } from 'react';
import { useGetBarItemsQuery, useSendBarItemMutation } from '../../api/marketplace';
import Response from '../../shared-components/response';
import './bar.css';
import { formatNumber } from '../../shared-components/numberFormat';

const BarPage = () => {
  const { data: barItems, isLoading, isError } = useGetBarItemsQuery();
  const [sendBarItem] = useSendBarItemMutation();
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [username, setUsername] = useState('');
  const [sendToFamily, setSendToFamily] = useState(false);
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handleItemClick = (item) => {
    setUsername('');
    setSelectedItem(item);
    setShowModal(true);
    setAlertData({ message: '', type: '' });
    setSendToFamily(false);
  };

  const confirmSend = async () => {
    try {
      const result = await sendBarItem({ 
        itemUuid: selectedItem.uuid, 
        recipientUsername: username,
        sendToFamily
      }).unwrap();
      setAlertData({ message: result.message || 'Item sent successfully!', type: result.type });
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Failed to send item. Please try again.', type: "fail" });
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading bar items</div>;

  return (
    <div className="row">
    <div className="col-lg-1"></div>
    <div className="col-lg-10">
      <Response message={alertData.message} type={alertData.type} />

      <div className="card">
        <div className="card-body">
          <h3 className="card-title text-center">Ghost's Boozer</h3>
          <h5 className="card-subtitle mb-2 text-muted text-center">
              You can only order a drink once every 4 hours.
            </h5>
            <hr />
          <div className="bar-grid">
            {barItems.map((item) => (
              <div key={item.uuid} className="bar-card" onClick={() => handleItemClick(item)}>
                <div className="bar-effect">{item.title}</div>
                <img src={`/assets/img/bar/${item.image}`} alt={item.title} />
                <div className="bar-price">£{formatNumber(item.priceCash)}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Send {selectedItem.title}</h5>
                <button type="button" className="close" onClick={() => setShowModal(false)}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Response message={alertData.message} type={alertData.type} />

                <p>{selectedItem.description}</p>
                <p>Price: £{formatNumber(selectedItem.priceCash)}</p>

                <p>Description: {selectedItem.description}</p>
                <div className="form-check mb-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="sendToFamily"
                    checked={sendToFamily}
                    onChange={(e) => setSendToFamily(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="sendToFamily">
                    Send to Family
                  </label>
                </div>
                {!sendToFamily && (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                )}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={confirmSend}>Send</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    <div className="col-lg-1"></div>
  </div>
  );
};

export default BarPage;
