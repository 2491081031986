import { apiSlice } from '../auth/apiSlice';

export const generalApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUpdates: builder.query({
      query: () => '/general/listupdates',
      providesTags: ['game_updates'],
    }),
    getLocations: builder.query({
      query: () => '/ui/listlocations',
      providesTags: ['travel_page'],
    }),
    getTravelPageData: builder.query({
      query: () => '/general/travelpagedata',
      providesTags: ['travel_page'],
    }),
    getGameStats: builder.query({
      query: () => '/general/gamestats',
      providesTags: ['travel_page'],
    }),
    getUserActivity: builder.query({
      query: () => '/general/useractivity',
      providesTags: ['travel_page'],
    }),
    travelPost: builder.mutation({
      query: (body) => ({
        url: '/general/travel',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['travel_page'],
    }),
    getBankBalance: builder.query({
      query: () => '/general/bankbalance',
      providesTags: ['bank_balance'],
    }),
    bankTransaction: builder.mutation({
      query: (body) => ({
        url: '/general/bankactions',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['bank_balance'],
    }),
    bankTransfer: builder.mutation({
      query: (body) => ({
        url: '/general/banktransfer',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['bank_balance'],
    }),
    createSavingsAccount: builder.mutation({
      query: (body) => ({
        url: '/general/createsavingsaccount',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['bank_balance', 'savings_account'],
    }),
    checkSavingsAccount: builder.query({
      query: () => ({
        url: '/general/savingscheck',
        method: 'GET',
      }),
      providesTags: ['bank_balance', 'savings_account'],
    }),
    checkATMAccount: builder.query({
      query: () => '/general/checkatmaccount',
      providesTags: ['atm_account'],
    }),
    openATMAccount: builder.mutation({
      query: (body) => ({
        url: '/general/openatmaccount',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['atm_account'],
    }),
    verifyPIN: builder.mutation({
      query: (body) => ({
        url: '/general/verifypin',
        method: 'POST',
        body,
      }),
    }),
    performATMAction: builder.mutation({
      query: (body) => ({
        url: '/general/performatmaction',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['atm_account'],
    }),
    getATMData: builder.query({
      query: () => '/general/getatmdata',
      providesTags: ['atm_account'],
    }),
    getUserStats: builder.query({
      query: () => '/general/dashboarduserstats',
      providesTags: ['user_stats'],
    }),
    getArticles: builder.query({
      query: () => '/general/getarticles',
      providesTags: ['articles'],
    }),
    createArticle: builder.mutation({
      query: (body) => ({
        url: '/general/createarticle',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['articles'],
    }), 
    searchUsers: builder.query({
      query: (searchTerm) => `/general/searchusers?query=${searchTerm}`,
      providesTags: ['users_search'],
  }),
  getPlayersOnline: builder.query({
    query: () => '/general/playersonline',
    providesTags: ['players_online'],
}),
getPlayerProfile: builder.query({
  query: (username) => `/general/playerprofile/${username}`,
  providesTags: ['player_profile'],
}),
getNotifications: builder.query({
  query: () => '/comms/getnotifications',
  providesTags: ['notifications'],
}),
getBoxingData: builder.query({
  query: () => '/general/boxingdata',
  providesTags: ['boxing_data'],
}),

submitBoxingTraining: builder.mutation({
  query: (body) => ({
    url: '/general/boxingtraining',
    method: 'POST',
    body,
  }),
  invalidatesTags: ['boxing_data'],
}),
getCharacterStats: builder.query({
  query: () => '/general/characterstats',
  providesTags: ['character_stats'],
}),
claimDailyReward: builder.mutation({
  query: () => ({
    url: '/general/claimdailyreward',
    method: 'POST',
  }),
  invalidatesTags: ['character_dashboard'],
}),
claimStarterPack: builder.mutation({
  query: () => ({
    url: '/general/claimstarterpack',
    method: 'POST',
  }),
  invalidatesTags: ['character_dashboard'],
}),
getUpgradeOptions: builder.query({
  query: (upgradeType) => `/general/upgrade-options/${upgradeType}`,
  providesTags: ['upgrades'],
}),
performUpgrade: builder.mutation({
  query: (body) => ({
    url: '/general/perform-upgrade',
    method: 'POST',
    body,
  }),
  invalidatesTags: ['upgrades', 'character_stats'],
}),
readAllNotifications: builder.mutation({
  query: () => ({
      url: '/comms/notifications/read-all',
      method: 'POST'
  }),
  invalidatesTags: ['notifications']
}),
getUserItems: builder.query({
  query: () => '/general/user-items',
  providesTags: ['user_items'],
}),

    

    // Add other general queries here as needed
  }),
  
});

export const { 
  useGetUpdatesQuery,
  useGetLocationsQuery,
  useGetTravelPageDataQuery,
  useTravelPostMutation,
  useGetGameStatsQuery,
  useGetBankBalanceQuery,
  useBankTransactionMutation,
  useBankTransferMutation,
  useCreateSavingsAccountMutation,
  useCheckSavingsAccountQuery,
  useCheckATMAccountQuery,
  useOpenATMAccountMutation,
  useVerifyPINMutation,
  usePerformATMActionMutation,
  useGetATMDataQuery,
  useGetUserStatsQuery,
  useGetArticlesQuery,
  useCreateArticleMutation,
  useSearchUsersQuery,
  useGetPlayersOnlineQuery,
  useGetPlayerProfileQuery,
  useGetNotificationsQuery,
  useGetBoxingDataQuery,
  useSubmitBoxingTrainingMutation,
  useGetUserActivityQuery,
  useGetCharacterStatsQuery,
  useClaimDailyRewardMutation,
  useClaimStarterPackMutation,
  useGetUpgradeOptionsQuery,
  usePerformUpgradeMutation,
  useReadAllNotificationsMutation,
  useGetUserItemsQuery,
} = generalApi;
