import React, { useState, useEffect } from 'react';
import './response.css';

const Response = ({ message, type, rouletteResult }) => {
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  useEffect(() => {
    if (message === '' && type === '') {
      setAlertData({ message: '', type: '' });
    } else if (message && type) {
      setAlertData({ message, type });
    }
  }, [message, type]);

  return (
    <div className="response-container">
      <div className="line-gap"></div>
      {alertData.message && (
        <div className={`alert alert-${alertData.type === 'success' ? 'success' : 'danger'}`} role="alert">
          {alertData.message}
        </div>
      )}
      {rouletteResult && (
        <div 
          className="roulette-result" 
          style={{
            width: '100px',
            height: '100px',
            backgroundColor: rouletteResult.color,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '24px',
            fontWeight: 'bold',
            margin: '10px auto'
          }}
        >
          {rouletteResult.number}
        </div>
      )}
    </div>
  );
};

export default Response;
