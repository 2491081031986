import { apiSlice } from '../auth/apiSlice';

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: credentials => ({
        url: '/auth/login',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    register: builder.mutation({
      query: body => ({
        url: 'auth/register',
        method: 'POST',
        body: { ...body },
      }),
    }),
    resetPassword: builder.mutation({
      query: body => ({
        url: 'auth/resetpass',
        method: 'POST',
        body: { ...body },
      }),
    }),
    getCharacters: builder.query({
      query: () => '/auth/listcharacters',
      providesTags: ['character_select_page'],
    }),
    postSelectCharacter: builder.mutation({
      query: body => ({
        url: '/auth/selectcharacter',
        method: 'POST',
        body: { ...body },
      }),
    }),
    createCharacter: builder.mutation({
      query: body => ({
        url: '/auth/createcharacter',
        method: 'POST',
        body: { ...body },
      }),
    }),
    getLocations: builder.query({
      query: () => '/ui/listlocations',
      providesTags: ['travel_page'],
    }),
    getTimers: builder.query({
      query: () => '/user/timers',
      providesTags: ['timers_page'],
    }),
    getStatsfeed: builder.query({
      query: () => '/user/statsfeed',
      providesTags: ['statsfeed'],
    })
  }),
});

export const { 
  useLoginMutation,
  useRegisterMutation,
  useResetPasswordMutation,
  useGetCharactersQuery,
  usePostSelectCharacterMutation,
  useCreateCharacterMutation,
  useGetLocationsQuery,
  useGetTimersQuery,
  useGetStatsfeedQuery
} = authApi;
