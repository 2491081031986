import React, { useState } from 'react';
import { usePlayRussianRouletteMutation, useGetRussianRouletteInfoQuery } from '../../api/kill';
import Response from '../../shared-components/response';

const RussianRoulettePage = () => {
  const { data: userInfo, isLoading } = useGetRussianRouletteInfoQuery();
  const [playRussianRoulette] = usePlayRussianRouletteMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await playRussianRoulette().unwrap();
      setAlertData({ message: result.message, type: result.type });
    } catch (error) {
      setAlertData({ message: 'Error playing Russian Roulette', type: 'danger' });
    }
  };

  if (isLoading) return <div>Loading...</div>;

  const limit = userInfo?.rank >= 12 ? 750000 * userInfo.rank : 0;

  return (
    <div className="background4">
      <Response message={alertData.message} type={alertData.type} />
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="card">
            <div className="card-body">
              <div className="text-center mb-4">
                <img src="/pages/kill/russianroulette/rrbanner.png" alt="Russian Roulette Banner" className="img-fluid" />
              </div>
              
              <div className="alert alert-warning">
                <p>Russian Roulette is taking the chance of killing your account (will not be revived if so) in exchange for money. Each rank you get sets a max limit. If you survive, you will double your money on hand!</p>
                <p className="mb-0"><strong>Good Luck.</strong></p>
              </div>

              <div className="alert alert-danger">
                <strong>Warning:</strong> You have 1 in 6 chance of dying. If you die, you lose your account, there will be no revives!
              </div>

              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Your Limit:</label>
                  <input type="text" className="form-control" value={limit.toLocaleString('en-US', { style: 'currency', currency: 'GBP' })} readOnly />
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-danger">Do It</button>
                </div>
              </form>
            </div>
            <div className="card-footer text-muted text-center">
              You need to be at least rank 12 before you can attempt this.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RussianRoulettePage;
