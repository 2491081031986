import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from'react-router-dom';
import { useDispatch } from'react-redux';
import { setCredentials } from '../../auth/authSlice.js';
import { useLoginMutation } from '../../api/auth.js';
import useIP from '../../auth/useIp.js';
import Response from '../../shared-components/response.jsx';
import { Link } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPass] = useState('');
  const [responseMessage, setResponseMessage] = useState({ message: '', type: '' });
  const { ip, locateInfo } = useIP();
  const dispatch = useDispatch();
  const [login] = useLoginMutation();

  useEffect(() => {
    setResponseMessage({ message: '', type: '' });
  }, [email, password]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const userData = await login({ email, password, ip, locateInfo }).unwrap();
      dispatch(setCredentials({ ...userData, email }));
      localStorage.setItem('token', userData.access_token);
      localStorage.setItem('user', JSON.stringify(userData.user));
      setEmail('');
      setPass('');
      setResponseMessage({ message: 'Login successful', type: 'success' });
      navigate('/game/general/dashboard');
    } catch (err) {
      if (err.data && err.data.error && err.data.error.length > 0) {
        setResponseMessage({
          message: err.data.error[0].message,
          type: err.data.error[0].type
        });
      } else {
        setResponseMessage({ message: 'An unexpected error occurred', type: 'failure' });
      }
    }
  }
  
  


  const context = useContext(AppSettings);

  useEffect(() => {
    context.handleSetAppSidebarNone(true);
    context.handleSetAppHeaderNone(true);
    context.handleSetAppContentClass('p-0');

    return () => {
      context.handleSetAppSidebarNone(false);
      context.handleSetAppHeaderNone(false);
      context.handleSetAppContentClass('');
    };
		// eslint-disable-next-line
	}, []);

    const handleEmailInput = (e) => setEmail(e.target.value)

    const handlePwdInput = (e) => setPass(e.target.value)




  return (
    <div className="login login-with-news-feed">
      <div className="news-feed">
        <div className="news-image background-image-cover" style={{backgroundImage: 'url(/assets/img/index/main_image.png)'}}></div>
        <div className="news-caption">
          <h4 className="caption-title"><b>UK</b> Mafia 2025</h4>
          <p>
            Welcome to the new UKM experience
          </p>
        </div>
      </div>
      <div className="login-container" style={{backgroundImage: 'url(/assets/img/index/background-gradient.png)'}}>
      <div className="login-header mb-30px">
      <div className="brand">
          <div className="d-flex align-items-center">
            <img src="/assets/img/index/index_logo.png" alt="UK Mafia Logo" className="logo-image" />
          </div>
        </div>
        </div>
        <div className="login-content">
          <form onSubmit={handleSubmit} className="fs-13px">
            <div className="form-floating mb-15px">
              <input type="text" className="form-control h-45px fs-13px" placeholder="Email Address" id="emailAddress" onChange={handleEmailInput} value={email} />
              <label htmlFor="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Email Address</label>
            </div>
            <div className="form-floating mb-15px">
              <input type="password" className="form-control h-45px fs-13px" placeholder="Password" id="password" onChange={handlePwdInput} value={password} />
              <label htmlFor="password" className="d-flex align-items-center fs-13px text-gray-600">Password</label>
            </div>
            <div className="form-check mb-30px">
              <input className="form-check-input" type="checkbox" value="1" id="rememberMe" />
              <label className="form-check-label" htmlFor="rememberMe">
                Remember Me
              </label>
            </div>
            <div className="mb-15px">
              <button type="submit" className="btn btn-theme d-block h-45px w-100 btn-lg fs-14px">Sign me in</button>
              <Response message={responseMessage.message} type={responseMessage.type} />
              </div>

            <div className="mb-40px pb-40px text-body">
              Not a member yet? Click <Link to="/register" className="text-primary">here</Link> to register.
            </div>
            <hr className="bg-gray-600 opacity-2" />
            <div className="text-gray-600 text-center text-gray-500-darker mb-0">
              &copy;UK Mafia All Right Reserved 2025
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;