import { apiSlice } from '../auth/apiSlice';

export const betaApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    giveXp: builder.mutation({
      query: (body) => ({
        url: '/beta/give-xp',
        method: 'POST',
        body: { ...body },
      }),
    }),
    giveMoney: builder.mutation({
      query: (body) => ({
        url: '/beta/give-money',
        method: 'POST',
        body: { ...body },
      }),
    }),
    givePoints: builder.mutation({
      query: (body) => ({
        url: '/beta/give-points',
        method: 'POST',
        body: { ...body },
      }),
    }),
    giveLockpicks: builder.mutation({
      query: (body) => ({
        url: '/beta/give-lockpicks',
        method: 'POST',
        body: { ...body },
      }),
    }),
    giveKevlars: builder.mutation({
      query: (body) => ({
        url: '/beta/give-kevlars',
        method: 'POST',
        body: { ...body },
      }),
    }),
    giveBullets: builder.mutation({
      query: (body) => ({
        url: '/beta/give-bullets',
        method: 'POST',
        body: { ...body },
      }),
    }),
    resetTimers: builder.mutation({
      query: (body) => ({
        url: '/beta/reset-timers',
        method: 'POST',
        body: { ...body },
      }),
    }),
  }),
});

export const {
  useGiveXpMutation,
  useGiveMoneyMutation,
  useGivePointsMutation,
  useGiveLockpicksMutation,
  useGiveKevlarsMutation,
  useGiveBulletsMutation,
  useResetTimersMutation,
} = betaApi;
