import { apiSlice } from '../auth/apiSlice';

export const pointsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPointsData: builder.query({
      query: () => 'points/data',
      providesTags: ['Points']
    }),

    transferPoints: builder.mutation({
      query: (data) => ({
        url: 'points/transfer',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Points']
    }),

    purchasePointsItem: builder.mutation({
      query: (data) => ({
        url: 'points/purchase',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Points']
    }),

    getPointsHistory: builder.query({
      query: () => 'points/history',
      providesTags: ['PointsHistory']
    }),

    activateGlobalBoost: builder.mutation({
      query: (data) => ({
        url: 'points/boost/global',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Points', 'GlobalBoosts']
    }),

    getActiveBoosts: builder.query({
      query: () => 'points/boosts/active',
      providesTags: ['GlobalBoosts']
    }),

    claimPointsRecovery: builder.mutation({
      query: (data) => ({
        url: 'points/recover',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Points']
    })
  })
});

export const {
  useGetPointsDataQuery,
  useTransferPointsMutation,
  usePurchasePointsItemMutation,
  useGetPointsHistoryQuery,
  useActivateGlobalBoostMutation,
  useGetActiveBoostsQuery,
  useClaimPointsRecoveryMutation
} = pointsApi;
