import React, { useState } from 'react';
import { useGetInvitesQuery, 
  useRespondToOCInviteMutation, 
  useCheckMembershipQuery,
  useStartOCMutation,
  useDoOCMutation,
  useDisbandOCMutation,
  useInviteToOCMutation,
  useGetLocalVehiclesQuery,
  useSubmitWeaponsMutation,
  useSubmitDemolitionsMutation,
  useSubmitVehicleMutation,
  
} from '../../api/oc';
import Response from '../../shared-components/response';

function StartOC() {
  const [splits, setSplits] = useState({
      leaderSplit: 40,
      weaponsSplit: 20,
      demolitionsSplit: 20,
      driverSplit: 20
  });
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [startOC] = useStartOCMutation();

  const handleSplitChange = (role, value) => {
      setSplits(prev => ({
          ...prev,
          [role]: parseInt(value) || 0
      }));
  };

  const handleSubmit = async () => {
    try {
        const response = await startOC(splits).unwrap();
        setAlertData({ message: response.message, type: response.type });
    } catch (error) {
        setAlertData({ message: error.data?.message || 'Error starting OC', type: 'error' });
    }
  };

  const totalSplit = Object.values(splits).reduce((a, b) => a + b, 0);

  return (
      <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
              <Response message={alertData.message} type={alertData.type} />
              <div className="card">
                  <div className="card-header">Start Organised Crime</div>
                  <div className="card-body">
                      <p className="text-center">It costs £120,000 to start an organised crime.</p>
                      <h5 className="text-center">What cut should everyone get?</h5>
                      <div className="row mb-3">
                          <div className="col-6">
                              <label>Leader:</label>
                              <input 
                                  type="number" 
                                  className="form-control" 
                                  max="100"
                                  value={splits.leaderSplit}
                                  onChange={(e) => handleSplitChange('leaderSplit', e.target.value)}
                              />
                          </div>
                          <div className="col-6">
                              <label>Driver:</label>
                              <input 
                                  type="number" 
                                  className="form-control" 
                                  max="100"
                                  value={splits.driverSplit}
                                  onChange={(e) => handleSplitChange('driverSplit', e.target.value)}
                              />
                          </div>
                      </div>
                      <div className="row mb-3">
                          <div className="col-6">
                              <label>Weapons Expert:</label>
                              <input 
                                  type="number" 
                                  className="form-control" 
                                  max="100"
                                  value={splits.weaponsSplit}
                                  onChange={(e) => handleSplitChange('weaponsSplit', e.target.value)}
                              />
                          </div>
                          <div className="col-6">
                              <label>Demolitions Expert:</label>
                              <input 
                                  type="number" 
                                  className="form-control" 
                                  max="100"
                                  value={splits.demolitionsSplit}
                                  onChange={(e) => handleSplitChange('demolitionsSplit', e.target.value)}
                              />
                          </div>
                      </div>
                      <p className="text-center">Total Split: {totalSplit}%</p>
                      <button 
                          className="btn btn-primary w-100" 
                          onClick={handleSubmit}
                          disabled={totalSplit !== 100}
                      >
                          Start OC
                      </button>
                  </div>
              </div>
          </div>
      </div>
  );
}

function CurrentInvites() {
  const { data: invites, isLoading } = useGetInvitesQuery();
  const [respondToOCInvite] = useRespondToOCInviteMutation();
  const { refetch: refetchMembership } = useCheckMembershipQuery();
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handleResponse = async (inviteUuid, response) => {
    try {
      const result = await respondToOCInvite({ 
        inviteUuid: inviteUuid, 
        response: response 
      }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      
      if (response === 'accept') {
        await refetchMembership();
      }
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Error responding to invite', type: 'error' });
    }
  };


  return (
    <div className="card mt-3">
      <div className="card-header">Current Invites</div>
      <div className="card-body">
        <Response message={alertData.message} type={alertData.type} />
        <table className="table">
          <thead>
            <tr>
              <th>Invited By</th>
              <th>Position</th>
              <th>Invite Received</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(invites) && invites.map(invite => (
              <tr key={invite.uuid}>
                <td>{invite.invitedBy?.username}</td>
                <td>{invite.position}</td>
                <td>{new Date(invite.createdAt).toLocaleString()}</td>
                <td>
                  <button 
                    className="btn btn-success btn-sm me-2"
                    onClick={() => handleResponse(invite.uuid, 'accept')}
                  >
                    Accept
                  </button>
                  <button 
                    className="btn btn-danger btn-sm"
                    onClick={() => handleResponse(invite.uuid, 'decline')}
                  >
                    Decline
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function ActiveOC() {
  const { data: membershipData, isLoading } = useCheckMembershipQuery();
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  if (isLoading) return <div>Loading...</div>;

  const ocData = membershipData.ocData;

  const formatEquipmentName = (type) => {
    const equipmentMap = {
        assault_rifle: 'Assault Rifles',
        baseball_bat: 'Baseball Bats',
        bb_gun: 'BB Guns',
        pistol: 'Pistols',
        fireworks: 'Fireworks',
        blast_charge: 'Blast Charges',
        semtex: 'Semtex',
        c4: 'C4'
    };
    return equipmentMap[type] || type;
};



  return (
    <div className="row">
      <div className="col-lg-1"></div>
      <div className="col-lg-10">
        <Response message={alertData.message} type={alertData.type} />
        
        {membershipData.userRole === 'weapons' && <WeaponsControl />}
        {membershipData.userRole === 'explosives' && <ExplosivesControl />}
        {membershipData.userRole === 'driver' && <DriverControl />}

        <div className="card mt-3">
          <div className="card-header text-center">
            INFORMATION ON YOUR OC IN: <span className="text-warning">{ocData.ocLocation?.city}</span>
          </div>
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Location</th>
                  <th>Status</th>
                  <th>Cut</th>
                  <th>Equipment</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Leader - {ocData.leader?.username}</td>
                  <td className={ocData.leader?.locationUuid === ocData.locationUuid ? 'text-success' : 'text-danger'}>
                    {ocData.leader?.location?.city}
                  </td>
                  <td className="text-success">Ready</td>
                  <td>{ocData.leaderSplit}%</td>
                  <td>Walkie Talkies</td>
                </tr>
                <tr>
                  <td>Weapons Expert - {ocData.weaponsExpert?.username || 'None'}</td>
                  <td className={ocData.weaponsExpert?.locationUuid === ocData.locationUuid ? 'text-success' : 'text-danger'}>
                    {ocData.weaponsExpert?.location?.city}
                  </td>
                  <td className={ocData.weaponsReady ? 'text-success' : 'text-danger'}>
                    {ocData.weaponsReady ? 'Ready' : 'Not Ready'}
                  </td>
                  <td>{ocData.weaponsSplit}%</td>
                  <td>{ocData.weaponsType ? `${ocData.weaponsAmount}x ${formatEquipmentName(ocData.weaponsType)}` : 'Not Set'}</td>

</tr>
                <tr>
                  <td>Demolitions Expert - {ocData.demolitionsExpert?.username || 'None'}</td>
                  <td className={ocData.demolitionsExpert?.locationUuid === ocData.locationUuid ? 'text-success' : 'text-danger'}>
                    {ocData.demolitionsExpert?.location?.city}
                  </td>
                  <td className={ocData.demolitionsReady ? 'text-success' : 'text-danger'}>
                    {ocData.demolitionsReady ? 'Ready' : 'Not Ready'}
                  </td>
                  <td>{ocData.demolitionsSplit}%</td>
                  <td>{ocData.demolitionsType ? `${ocData.demolitionsAmount}x ${formatEquipmentName(ocData.demolitionsType)}` : 'Not Set'}</td>

</tr>
                <tr>
                  <td>Driver - {ocData.driver?.username || 'None'}</td>
                  <td className={ocData.driver?.locationUuid === ocData.locationUuid ? 'text-success' : 'text-danger'}>
                    {ocData.driver?.location?.city}
                  </td>
                  <td className={ocData.driverReady ? 'text-success' : 'text-danger'}>
                    {ocData.driverReady ? 'Ready' : 'Not Ready'}
                  </td>
                  <td>{ocData.driverSplit}%</td>
                  <td>
  {ocData.selectedVehicle ? (
    `${ocData.selectedVehicle.vehicle.title} - Damage: ${
      100 - Math.round(
        ((ocData.selectedVehicle.value - ocData.selectedVehicle.vehicle.minValue) / 
        (ocData.selectedVehicle.vehicle.maxValue - ocData.selectedVehicle.vehicle.minValue)) * 100
      )
    }%`
  ) : 'Not Set'}
</td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>

        {membershipData.userRole === 'leader' && <LeaderControl setAlertData={setAlertData} />}
        </div>
      <div className="col-lg-1"></div>
    </div>
  );
}

function LeaderControl({ onDoOC, onDisband, setAlertData }) {
  const [username, setUsername] = useState('');
  const [position, setPosition] = useState('Weapons Expert');
  const [inviteToOC] = useInviteToOCMutation();
  const [doOC] = useDoOCMutation();

  const handleInvite = async () => {
    try {
      const result = await inviteToOC({ username, position }).unwrap();
      setAlertData({ message: result.message, type: result.type });
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Error inviting player', type: 'error' });
    }
  };

  const handleDoOC = async () => {
    try {
        const result = await doOC().unwrap();
        setAlertData({ message: result.message, type: result.type });
    } catch (error) {
        setAlertData({ message: error.data?.message || 'Error executing OC', type: 'error' });
    }
};



  return (
    <div className="card mt-3">
      <div className="card-header">Leader Control</div>
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-4">
            <input 
              type="text" 
              className="form-control" 
              placeholder="Username" 
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="col-4">
            <select 
              className="form-control"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            >
              <option>Weapons Expert</option>
              <option>Demolitions Expert</option>
              <option>Driver</option>
            </select>
          </div>
          <div className="col-4">
            <button 
              className="btn btn-primary w-100"
              onClick={handleInvite}
            >
              Invite
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <button 
              className="btn btn-success w-100"
              onClick={handleDoOC}
              >
              Do Organised Crime
            </button>
          </div>
          <div className="col-6">
            <button 
              className="btn btn-danger w-100"
              onClick={onDisband}
            >
              Disband OC
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function OrganisedCrime() {
  const [activeTab, setActiveTab] = useState('start');
  const { data: membershipData } = useCheckMembershipQuery();

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  if (membershipData?.inOC) {
    return <ActiveOC />;
  }

  return (
    <div className="family-tabs-container">
      <div className="tabs-wrapper">
        <ul className="nav nav-tabs flex-nowrap">
          <li className="nav-item">
            <a 
              href="#start-tab" 
              onClick={() => handleTabClick('start')} 
              className={`nav-link ${activeTab === 'start' ? 'active' : ''}`}
            >
              Start OC
            </a>
          </li>
          <li className="nav-item">
            <a 
              href="#invites-tab" 
              onClick={() => handleTabClick('invites')} 
              className={`nav-link ${activeTab === 'invites' ? 'active' : ''}`}
            >
              Current Invites
            </a>
          </li>
        </ul>
      </div>

      <div className="tab-content panel rounded-0 p-3 m-0">
        <div className={`tab-pane fade ${activeTab === 'start' ? 'active show' : ''}`} id="start-tab">
          {activeTab === 'start' && <StartOC />}
        </div>
        <div className={`tab-pane fade ${activeTab === 'invites' ? 'active show' : ''}`} id="invites-tab">
          {activeTab === 'invites' && <CurrentInvites />}
        </div>
      </div>
    </div>
  );
}

function WeaponsControl() {
  const { data: membershipData } = useCheckMembershipQuery();
  const [selectedWeapon, setSelectedWeapon] = useState('');
  const [amount, setAmount] = useState(1);
  const [submitWeapons] = useSubmitWeaponsMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const isReady = membershipData?.ocData?.weaponsReady;

  const handleSubmit = async () => {
    try {
      const result = await submitWeapons({ 
        weaponType: selectedWeapon, 
        amount 
      }).unwrap();
      setAlertData({ message: result.message, type: result.type });
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Error submitting weapons', type: 'error' });
    }
  };

  return (
    <div className="card mt-3">
      <div className="card-header">Weapons Expert Control</div>
      <div className="card-body">
        <Response message={alertData.message} type={alertData.type} />
        {isReady ? (
          <div className="text-center">
            <h5 className="text-success">Ready for OC</h5>
            <p>Selected: {membershipData.ocData.weaponsAmount}x {membershipData.ocData.weaponsType}</p>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-6">
                <select 
                  className="form-control"
                  value={selectedWeapon}
                  onChange={(e) => setSelectedWeapon(e.target.value)}
                >
                  <option value="">Select Weapon</option>
                  <option value="baseball_bat">Baseball Bat</option>
                  <option value="bb_gun">BB Gun</option>
                  <option value="pistol">Pistol</option>
                  <option value="assault_rifle">Assault Rifle</option>
                </select>
              </div>
              <div className="col-6">
                <input 
                  type="number" 
                  className="form-control"
                  min="1"
                  max="4"
                  value={amount}
                  onChange={(e) => setAmount(Math.min(4, parseInt(e.target.value) || 0))}
                />
              </div>
            </div>
            <button 
              className="btn btn-primary w-100 mt-3"
              onClick={handleSubmit}
              disabled={!selectedWeapon || amount < 1}
            >
              Submit Weapons
            </button>
          </>
        )}
      </div>
    </div>
  );
}

function ExplosivesControl() {
  const { data: membershipData } = useCheckMembershipQuery();
  const [selectedExplosive, setSelectedExplosive] = useState('');
  const [amount, setAmount] = useState(1);
  const [submitDemolitions] = useSubmitDemolitionsMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const isReady = membershipData?.ocData?.demolitionsReady;

  const handleSubmit = async () => {
    try {
      const result = await submitDemolitions({ 
        explosiveType: selectedExplosive, 
        amount 
      }).unwrap();
      setAlertData({ message: result.message, type: result.type });
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Error submitting explosives', type: 'error' });
    }
  };

  return (
    <div className="card mt-3">
      <div className="card-header">Explosives Expert Control</div>
      <div className="card-body">
        <Response message={alertData.message} type={alertData.type} />
        {isReady ? (
          <div className="text-center">
            <h5 className="text-success">Ready for OC</h5>
            <p>Selected: {membershipData.ocData.demolitionsAmount}x {membershipData.ocData.demolitionsType}</p>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-6">
                <select 
                  className="form-control"
                  value={selectedExplosive}
                  onChange={(e) => setSelectedExplosive(e.target.value)}
                >
                  <option value="">Select Explosive</option>
                  <option value="fireworks">Fireworks</option>
                  <option value="blast_charge">Blast Charge</option>
                  <option value="semtex">Semtex</option>
                  <option value="c4">C4</option>
                </select>
              </div>
              <div className="col-6">
                <input 
                  type="number" 
                  className="form-control"
                  min="1"
                  max="4"
                  value={amount}
                  onChange={(e) => setAmount(Math.min(4, parseInt(e.target.value) || 0))}
                />
              </div>
            </div>
            <button 
              className="btn btn-primary w-100 mt-3"
              onClick={handleSubmit}
              disabled={!selectedExplosive || amount < 1}
            >
              Submit Explosives
            </button>
          </>
        )}
      </div>
    </div>
  );
}

function DriverControl() {
  const { data: membershipData } = useCheckMembershipQuery();
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const { data: vehicles } = useGetLocalVehiclesQuery();
  const [submitVehicle] = useSubmitVehicleMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const isReady = membershipData?.ocData?.driverReady;

  const handleSubmit = async () => {
    try {
      const result = await submitVehicle({ 
        vehicleUuid: selectedVehicle 
      }).unwrap();
      setAlertData({ message: result.message, type: result.type });
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Error submitting vehicle', type: 'error' });
    }
  };

  return (
    <div className="card mt-3">
      <div className="card-header">Driver Control</div>
      <div className="card-body">
        <Response message={alertData.message} type={alertData.type} />
        {isReady ? (
          <div className="text-center">
            <h5 className="text-success">Ready for OC</h5>
            <p>Vehicle Selected</p>
          </div>
        ) : (
          <>
            <select 
              className="form-control"
              value={selectedVehicle}
              onChange={(e) => setSelectedVehicle(e.target.value)}
            >
              <option value="">Select Vehicle</option>
              {vehicles?.map(vehicle => (
                <option key={vehicle.uuid} value={vehicle.uuid}>
                  {vehicle.name} - {vehicle.condition}%
                </option>
              ))}
            </select>
            <button 
              className="btn btn-primary w-100 mt-3"
              onClick={handleSubmit}
              disabled={!selectedVehicle}
            >
              Submit Vehicle
            </button>
          </>
        )}
      </div>
    </div>
  );
}




export default OrganisedCrime;
