import { apiSlice } from '../auth/apiSlice';

export const miscApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: () => '/misc/get-user-profile',
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: '/misc/update-profile',
        method: 'POST',
        body,
      }),
    }),
    updateProfileImage: builder.mutation({
      query: (body) => ({
        url: '/misc/update-profile-image',
        method: 'POST',
        body,
      }),
    }),
    updateProfileMusic: builder.mutation({
      query: (body) => ({
        url: '/misc/update-profile-music',
        method: 'POST',
        body,
      }),
    }),
    updateForumImage: builder.mutation({
      query: (body) => ({
        url: '/misc/update-forum-image',
        method: 'POST',
        body,
      }),
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: '/misc/change-password',
        method: 'POST',
        body,
      }),
    }),
    changeSecurityPin: builder.mutation({
      query: (body) => ({
        url: '/misc/change-security-pin',
        method: 'POST',
        body,
      }),
    }),
    toggleSecurityPin: builder.mutation({
      query: (body) => ({
        url: '/misc/toggle-security-pin',
        method: 'POST',
        body,
      }),
    }),
    updateBackfireSettings: builder.mutation({
      query: (body) => ({
        url: '/misc/update-backfire-settings',
        method: 'POST',
        body,
      }),
    }),
    updateProfileVisibility: builder.mutation({
      query: (body) => ({
        url: '/misc/update-profile-visibility',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useUpdateProfileMutation,
  useUpdateProfileImageMutation,
  useUpdateProfileMusicMutation,
  useUpdateForumImageMutation,
  useChangePasswordMutation,
  useChangeSecurityPinMutation,
  useToggleSecurityPinMutation,
  useUpdateBackfireSettingsMutation,
  useUpdateProfileVisibilityMutation,
} = miscApi;
