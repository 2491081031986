import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPrisonStatus } from '../auth/authSlice';
import { useCheckPrisonStatusQuery } from '../api/crime';

const YouAreInPrison = () => {
    const dispatch = useDispatch();
    const { data } = useCheckPrisonStatusQuery(undefined, {
        pollingInterval: 2000 // Check every 2 seconds
    });

    console.log('Prison check response:', data);

    useEffect(() => {
        if (data?.inPrison === false) {
            console.log('Freedom detected - updating state');
            dispatch(setPrisonStatus(false));
        }
    }, [data, dispatch]);

    return (
        <div className="prison-container" style={{ textAlign: 'center', padding: '20px' }}>
            <h2>You are currently in Prison</h2>
            <p>You cannot access this feature while incarcerated.</p>
        </div>
    );
};

export default YouAreInPrison;
