import React from 'react';
import { useGetLocationsQuery } from "../api/auth";

const LocationDropdown = ({ onLocationChange }) => {
  const { data: locations, isLoading, error } = useGetLocationsQuery();

  const handleLocationChange = (event) => {
    const selectedLocationUuid = event.target.value;
    onLocationChange(selectedLocationUuid);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="form-group">
      <label htmlFor="location-dropdown">Select Location</label>
      <select
        className="form-control"
        id="location-dropdown"
        onChange={handleLocationChange}
      >
        <option value="">Choose a location</option>
        {locations.map((location) => (
          <option key={location.uuid} value={location.uuid}>
            {location.city}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LocationDropdown;
