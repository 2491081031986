import React, { useState } from 'react';
import { useGetBoxingDataQuery, useSubmitBoxingTrainingMutation } from '../../api/general';
import Response from '../../shared-components/response';
import { useGlobalState } from '../../auth/globalState';


const TrainingOptions = ({ trainingOptions, onTrainingResponse }) => {
  const [submitTraining] = useSubmitBoxingTrainingMutation();
  const { updateTimer } = useGlobalState();

  const handleDoubleClick = async (trainingType) => {
    try {
      const result = await submitTraining({ trainingType });
      if (result.data) {
        onTrainingResponse(result.data.message, result.data.type);
      }
      if (result.data.time) {
        updateTimer('boxing', result.data.time);
      }
    } catch (error) {
      onTrainingResponse('An error occurred during training', 'error');
    }
  };

  const formatWaitTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Training Options</h5>
        <div className="row">
          {trainingOptions.map((option, index) => (
            <div key={index} className="col-6 mb-3">
              <div className="position-relative">
                <div className="position-absolute top-0 start-0 end-0 bg-black text-white p-1 text-center">
                  {formatWaitTime(option.waitTime)}
                </div>
                <img
                  src={"https://placehold.co/600x400"}
                  alt={option.name}
                  className="img-fluid"
                  onDoubleClick={() => handleDoubleClick(option.type)}
                  style={{ cursor: 'pointer' }}
                />
                <div className="position-absolute bottom-0 start-0 end-0 bg-black text-white p-1 text-center">
                  Gain: {option.percentage}%
                </div>
              </div>
              <p className="mt-2">{option.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const BoxingStats = ({ stats }) => {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Your Stats</h5>
        <div className="row align-items-center mb-3">
          <div className="col-md-3">
            <h6>Boxing Level: {stats.level}</h6>
          </div>
          <div className="col-md-9">
            <div className="progress">
              <div 
                className="progress-bar" 
                role="progressbar" 
                style={{width: `${stats.percentage}%`}} 
                aria-valuenow={stats.percentage} 
                aria-valuemin="0" 
                aria-valuemax="100"
              >
                {stats.percentage}%
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <h6>Wins: {stats.wins}</h6>
          </div>
          <div className="col-md-4">
            <h6>Losses: {stats.losses}</h6>
          </div>
          <div className="col-md-4">
            <h6>Draws: {stats.draws}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

const Boxing = () => {
  const { data: boxingData, isLoading, isError } = useGetBoxingDataQuery();
  const [response, setResponse] = useState({ message: '', type: '' });

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error loading boxing data</p>;

  const handleTrainingResponse = (message, type) => {
    setResponse({ message, type });
  };

  return (
    <div>
      <Response message={response.message} type={response.type} />
      <div className="row">
        <div className="col-md-6">
          <TrainingOptions 
            trainingOptions={boxingData.trainingOptions} 
            onTrainingResponse={handleTrainingResponse}
          />
          <br />
        </div>
        <div className="col-md-6">
          <BoxingStats stats={boxingData.stats} />
        </div>
      </div>
    </div>
  );
};

export default Boxing;
