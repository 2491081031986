import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetPropertyDetailsQuery, useIsPropertyOwnerMutation, useBuyFromFactoryMutation } from '../../api/properties';
import PropertyPanel from './propertyPanel';
import Response from '../../shared-components/response';
import { formatNumber } from '../../shared-components/numberFormat';

const KevlarFactory = () => {
  const { data: propertyDetails, isLoading, refetch } = useGetPropertyDetailsQuery("kevlarFactory");
  const [checkOwnership, { data: ownershipData }] = useIsPropertyOwnerMutation();
  const [buyFromFactory] = useBuyFromFactoryMutation();
  const [buyAmount, setBuyAmount] = useState('');
  const [response, setResponse] = useState({ message: '', type: '' });

  React.useEffect(() => {
    checkOwnership({ property: "kevlarFactory" });
  }, [checkOwnership]);

  const handleBuy = async () => {
    try {
      const result = await buyFromFactory({ factoryType: 'kevlarFactory', amount: buyAmount }).unwrap();
      setResponse({ message: result.message, type: result.type });
      refetch();
    } catch (error) {
      setResponse({ message: error.data?.message || 'API ERROR', type: 'danger' });
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="property-page">
            {ownershipData?.isOwner ? (
              <PropertyPanel property="kevlarFactory" /> 
            ) : propertyDetails.owner === null ? ( 
              <div>
                <p>Nobody owns this property. You can pick it up at the&nbsp;
                  <Link to="/game/general/properties">locations page</Link>.
                </p>
              </div>
            ) : (
              <>
                          <Response message={response.message} type={response.type} />

                <div className="card">
                  <img src="/assets/img/kevlarfactory.webp" alt="Kevlar Factory" className="card-img-top" />
                  <div className="card-body">
                    <h5 className="card-title">Kevlar Factory</h5>
                    <p className="card-text">Current Stock: {formatNumber(propertyDetails.stock)}</p>
                    <p className="card-text">Price: ${formatNumber(propertyDetails.price)}</p>

                    <div className="input-group mb-3">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Amount to buy"
                        value={buyAmount}
                        onChange={(e) => setBuyAmount(e.target.value)}
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="button" onClick={handleBuy}>Buy</button>
                      </div>
                    </div>
                    <div className="mt-3">
                    <p>Current Owner: 
  {propertyDetails.owner ? (
    <Link to={`/game/general/userprofile/${propertyDetails.owner}`}>
      {propertyDetails.owner}
    </Link>
  ) : 'None'}
</p>
                      <p>Profit Made: ${formatNumber(propertyDetails.totalProfit)}</p>

                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KevlarFactory;
