import React, { useState } from 'react';
import { usePrisonListQuery, usePrisonBustMutation } from '../../api/crime';
import Response from '../../shared-components/response';

const Prison = () => {
  const { data: prisonList, isLoading, isError, refetch } = usePrisonListQuery();
  const [prisonBust] = usePrisonBustMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handlePrisonBust = async (uuid) => {
    try {
      const result = await prisonBust({ uuid }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      refetch(); // Refetch the prison list
    } catch (error) {
      setAlertData({ message: error.data?.message || 'Prison bust failed', type: 'danger' });
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading prison list</div>;

  return (
    <div>
      <Response 
        message={alertData.message} 
        type={alertData.type}
      />
      <div className="row">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
          <div className="panel rounded-0 p-3 m-0">
            <h3>Prison</h3>
            <div className="prison-list-container">
              {prisonList && prisonList.map((prisoner) => (
                <button 
                  key={prisoner.uuid} 
                  className="prisoner-item btn btn-primary w-100 mb-2"
                  onClick={() => handlePrisonBust(prisoner.uuid)}
                >
                  {prisoner.displayName}
                </button>
              ))}
            </div>

          </div>
        </div>
        <div className="col-lg-1"></div>
      </div>
    </div>
  );
};

export default Prison;
