
function addGamePrefix(menuItems) {
  return menuItems.map(item => {
    const newItem = { ...item };
    if (newItem.path && !newItem.path.startsWith('/game')) {
      newItem.path = `/game${newItem.path}`;
    }
    if (newItem.children) {
      newItem.children = addGamePrefix(newItem.children);
    }
    return newItem;
  });
}

const Menu = addGamePrefix([
  {
    path: '/general', icon: 'fa fa-sitemap', title: 'General',
    children: [
      { path: '/general/beta', title: 'Beta testers' },
      { path: '/general/dashboard', title: 'Dashboard' },
      { path: '/general/travel', title: 'Travel' },
      { path: '/general/finance', title: 'Your Money' },
      { path: '/general/player-directory', title: 'Player Directory' },
      { path: '/general/properties', title: 'Properties' },
      { path: '/family/family', title: 'Family' },
      { path: '/general/items', title: 'Items' }
    ]
  },
  {
    path: '/messaging', icon: 'fa fa-envelope', title: 'Messaging',
    children: [
      { path: '/messaging/inbox', title: 'Inbox' },
      { path: '/messaging/idea-centre', title: 'Idea Centre' },
      { path: '/messaging/main-forum?forum=main', title: 'Main Forum' },
      { path: '/messaging/main-forum?forum=family', title: 'Family Forum' },
    ]
  },
  {
    path: '/crime', icon: 'fa fa-mask', title: 'Crime',
    children: [
      { path: '/crime/missions', title: 'Missions' },
      { path: '/crime/organised-crime', title: 'Organised Crime' },
      { path: '/crime/drugs', title: 'Drugs' },
      { path: '/crime/prison', title: 'Prison' },
      { path: '/crime/encounters', title: 'Encounters' },
      { path: '/crime/syndicates', title: 'Syndicates' },
    ]
  },
  {
    path: '/kill', icon: 'fa fa-skull', title: 'Kill',
    children: [
      { path: '/kill/kill', title: 'Kill' },
      { path: '/kill/bullet-factory', title: 'Bullet Factory'},
      { path: '/kill/kevlar-factory', title: 'Kevlar Factory' },
      { path: '/kill/foundry', title: 'Foundry' },
      { path: '/kill/russian-roulette', title: 'Russian Roulette' },
    ]
  },
  {
    path: '/marketplace', icon: 'fa fa-store', title: 'Marketplace',
    children: [
      { path: '/marketplace/refinery', title: 'Refinery' },
      { path: '/marketplace/bar', title: 'Bar' },
      { path: '/marketplace/black-market', title: 'Black Market' },
      { path: '/marketplace/vehicles', title: 'Vehicles' },
      { path: '/marketplace/life-insurance', title: 'Life Insurance' },
      { path: '/marketplace/points', title: 'Points' }

    ]
  },
  {
    path: '/casino', icon: 'fa fa-dice', title: 'Casino',
    children: [
      { path: '/casino/roulette', title: 'Roulette' },
      { path: '/casino/dice', title: 'Dice' },
      { path: '/casino/slots', title: 'Slots' },
      { path: '/casino/heads-or-tails', title: 'Heads or Tails' },
      { path: '/casino/boxing', title: 'Boxing' },
      { path: '/casino/lottery', title: 'Lottery' },
      { path: '/casino/keno', title: 'Keno' },
      { path: '/casino/scratchcards', title: 'Scratchcards' },
    ]
  },
]);
export default Menu;